<template>
  <div>
    <div class="upload-img">
      <ul class="img-list">
        <li class="item" v-for="(item, index) in imgList" :key="index" @mouseenter="imgmouseover(index)" @mouseleave="imgmouseout(index)">
          <img :src="item.path" alt="" />
          <span class="item-actions" :style="{ opacity: item.isSelect ? 1 : 0 }">
            <span @click="removeImg(index)" class="item-delete"><i class="el-icon-delete"></i></span>
          </span>
        </li>
        <li class="item" @mouseover="is_active = !0" @mouseout="is_active = !1" v-if="type == 2 && video">
          <video :src="video"></video>
          <span class="item-actions" :style="{ opacity: is_active ? 1 : 0 }">
            <span @click="removeVideo" class="item-delete"><i class="el-icon-delete"></i></span>
          </span>
        </li>
      </ul>
      <div @click="addImg" :class="['add-img', showBorder ? 'add-img-active' : '']">
        <div @mouseover="showBorder = !0" @mouseout="showBorder = !1">
          <i class="el-icon-plus"></i>
        </div>
        <input v-if="type == 2" type="file" @change="getVideoFile" ref="inputer" accept="video/mp4" />
      </div>
    </div>
    <ImgLibrary :isMany="true" v-if="showImgLibrary" @close="close" @changeStatus="changeStatus" :dialogVisible="showImgLibrary" @getImg="getImg"></ImgLibrary>
  </div>
</template>

<script>
import ImgLibrary from '@/components/imgLibrary'
export default {
  components: {
    ImgLibrary
  },
  props: {
    //支持图片数量
    num: {
      type: Number,
      default: 5
    },
    type: {
      //1默认图片 2视频
      type: Number,
      default: 1
    },
    imgLists: {
      default: []
    },
    video: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      showBorder: !1,
      showImgLibrary: !1,
      is_active: !1,
      imgList: []
    }
  },
  watch: {
    imgLists: {
      handler(val) {
        this.imgList = val
      },
      immediate: !0
    },
    showImgLibrary(val) {
      this.$emit('changeStatus', val)
    }
  },
  methods: {
    changeStatus(val) {
      this.showImgLibrary = val
    },
    addImg() {
      if (this.type == 1) {
        if (this.imgList.length == this.num) return this.$message.error(`最多只能添加${this.num}张图片`)
        this.showImgLibrary = !0
      }
    },
    getVideoFile() {
      let formData = new FormData()
      let files = this.$refs.inputer.files[0]
      formData.append('files', files)
      this.$axios.uploadFile(this.$api.user.addFiles, formData).then((res) => {
        if (res.code == 0) {
          this.video = res.result.path
          this.$emit('getVideo', res.result.path)
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    removeImg(i) {
      this.imgList.splice(i, 1)
      this.$emit('getImg', this.imgList)
    },
    removeVideo() {
      this.video = ''
      this.$emit('getVideo', '')
    },
    imgmouseover(i) {
      this.$set(this.imgList[i], 'isSelect', true)
    },
    imgmouseout(i) {
      this.$set(this.imgList[i], 'isSelect', false)
    },
    close() {
      this.showImgLibrary = !1
    },
    getImg(val) {
      this.showImgLibrary = !1
      if (this.imgList.length + val.length > this.num)
        return this.$confirm('图片超出数量大小，请重新选择', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'error'
        }).then(() => {
          this.showImgLibrary = !0
        })
      this.imgList.push(...val)
      for (let i in this.imgList) this.$set(this.imgList[i], 'isSelect', false)
      this.$emit('getImg', this.imgList)
    }
  }
}
</script>

<style lang="less" scoped>
.upload-img {
  display: flex;
  align-items: center;
  height: 110px;
  margin-bottom: 15px;
  .img-list {
    height: 100%;
    display: flex;
    .item {
      height: 100%;
      width: 110px;
      margin-right: 10px;
      border-radius: 6px;
      background-size: cover;
      position: relative;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
      }
      video {
        width: 100%;
        height: 100%;
      }
      .item-actions {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        z-index: 10;
        left: 0;
        border-radius: 6px;
        opacity: 0;
        background-color: rgba(0, 0, 0, 0.5);
        transition: opacity 0.3s;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 20px;
        color: #fff;
      }
    }
  }
  .add-img {
    height: 100%;
    width: 110px;
    background-color: #fbfdff;
    border: 1px dashed #c0ccda;
    border-radius: 6px;
    cursor: pointer;
    font-size: 25px;
    color: #8c939d;
    position: relative;
    div {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    input {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      opacity: 0;
    }
  }
  .add-img-active {
    border-color: #409eff;
  }
}
</style>
